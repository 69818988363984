<template>
  <div>
    <MainHeader />
    
    <HdriHero class="z-10" />

    <HdriFeatures />

    <HomeLogoWall />

    <HdriSequenceInfo />

    <LazyHdriFreeDownloads />

    <LazyHdriQuote />

    <LazyHdriFeatureSkyBackplates cta1="View our HDRI sequences" cta2="Take a look at our backplates" />

    <LazyHdriSkyBackplates />

    <LazyHdriDownloadUpSell />

    <LazyHomeCreateInspiringWorlds />

    <MainFooter />

    <Head>
      <Meta name="description" content="50k HDRI’s suitable for IBL and in-camera content. HDRIs every 90 seconds at 50,000px resolution." />
    </Head>
  </div>
</template>

<script>
export default {
  setup() {
    useHead({
      title: 'Timelapse HDRI\'s > World-Class HDRI Library shot at 50k | MattePaint',
      meta: [{ name: 'description', content: '50k HDRI’s suitable for IBL and in-camera content. HDRIs every 90 seconds at 50,000px resolution.' }],
    })
    useSeoMeta({
      title: 'Timelapse HDRI\'s > World-Class HDRI Library shot at 50k | MattePaint',
      ogUrl: 'https://mattepaint.com/hdris/',
      ogTitle: 'Timelapse HDRI\'s > World-Class HDRI Library shot at 50k | MattePaint',
      description: '50k HDRI’s suitable for IBL and in-camera content. HDRIs every 90 seconds at 50,000px resolution.',
      ogDescription: '50k HDRI’s suitable for IBL and in-camera content. HDRIs every 90 seconds at 50,000px resolution.',
      ogImage: 'https://assets-cdn.mattepaint.com/seo_hdris.jpg',
      twitterTitle: 'Timelapse HDRI\'s > World-Class HDRI Library shot at 50k | MattePaint',
      twitterDescription: '50k HDRI’s suitable for IBL and in-camera content. HDRIs every 90 seconds at 50,000px resolution.',
      twitterImage: 'https://assets-cdn.mattepaint.com/seo_home.jpg',
      twitterCard: 'summary_large_image'
    })
    useServerSeoMeta({
      title: 'Timelapse HDRI\'s > Our World-Class HDRI Library shot at 50k | MattePaint',
      ogTitle: 'Timelapse HDRI\'s > World-Class HDRI Library shot at 50k | MattePaint',
      description: '50k HDRI’s suitable for IBL and in-camera content. HDRIs every 90 seconds at 50,000px resolution.',
      ogDescription: '50k HDRI’s suitable for IBL and in-camera content. HDRIs every 90 seconds at 50,000px resolution.',
      ogImage: 'https://assets-cdn.mattepaint.com/seo_hdris.jpg',
      twitterTitle: 'Timelapse HDRI\'s > World-Class HDRI Library shot at 50k | MattePaint',
      twitterDescription: '50k HDRI’s suitable for IBL and in-camera content. HDRIs every 90 seconds at 50,000px resolution.',
      twitterImage: 'https://assets-cdn.mattepaint.com/seo_home.jpg',
      twitterCard: 'summary_large_image'
    })
  },
  data() {
    return {
      ready: false
    };
  },
  mounted() {
    this.ready = true;
  }
}
</script>