<template>
  <section class="w-full relative aspect-video max-h-[550px] lg:min-h-[550px] items-center justify-center flex flex-col space-y-4 lg:space-y-12 py-20 px-5" style="background-size:100% 100%;background-image:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAOCAYAAABO3B6yAAAAAXNSR0IArs4c6QAAAopJREFUSEuVVT2LFEEQfdUfayCHXHQYiRiIiB6I+BWaHJica2xoZCDoz3e6u+RVVc/M6ip40Mwy7HW9el8rT25/qKpCx8AYAzo6Ru/2BBRJgJwSas041Io7h4paK0opSEnA/+29Y1man7ZgaXEXBJAEkQSkeIoAECj8T55+/P7fAAiEAGQHoC0NPxcOb2itow+FEgCPDc8QDhcO53uuB8j18VsAUAxuP3x7MgIdSCLIWVBLweGwYyDnYADOQCMDi7EwAQzfEZKSMWEn3hkDCsiL41flJx0OwCSYAEKCspOAIIyBfwBYekfvI2jmYJfCqQ/6KTvfvDp+cQbMB9vwvQf+BoASwDwwThkgAHrK1uTAABHDfV5I8Ob28wkAN2MHKAEoQZiwuAmdgYJSqCm3chOSdpNg54FhW07Tue681YYHCHn94ZPB2ZIQHtAB4RGADHAgB68A8pYCpqfRA+aDhtY9BWTA3R7UK/ydKgycKuTl+xv3gDKCvj2fqwRQj2HJqAQQx2JokQL66OhkYG5PCXY0u+6MLAHQa7EwAVy/fXdWAgcwqB5yEjPd7IIpQQoJuK3JEFKY/hZDrhYScDhvpNmjdwhIHj9/tkqw3958QAmMAQJgGRUcSrEnJSEzliYC4GkdzYrsN/qZfeVNvv0Kggw8ePTwTAq2LpgAMgHQB+cAGK0evXX7qDo1mRwo9XcJCDA8cHX/KgDsPNA9jlAeMrAlYbJABpI1nNvaZIg6d6PRe958E8D0gLWkOgi5vLxnYWERzR4wKfh7wC8oPIoZqMUbkWdK4D6c1E7tt/hZVKN+NxPuGLi4uPtnExoDEVrity4ggDj0QM6gLNbvM1Yn5uPcWcE7I04JzKSKX/RlxaJKWojgAAAAAElFTkSuQmCC')">
    <div class="z-0 backdrop-blur-xxl">
      <ClientOnly>
        <Transition name="fade">
          <img
            v-if="isIdle"
            class="absolute bottom-0 inset-0 object-cover top-0 left-0 right-0 h-full w-full max-w-none z-0"
            :src="bg"
            :srcset="srcset"
            alt="MattePaint HDRI Background"
            fetchpriority="highest"
            preload
            prefetch
          />
        </Transition>
      </ClientOnly>
      <div class="z-10 absolute bottom-0 inset-0 bg-gradient-to-t from-black-900/30 to-transparent" />
    </div>
    <h1 class="z-10 text-lg">
      Timelapse HDRI's
    </h1>
    <h2 class="z-10 text-4xl lg:text-6xl font-bold text-lightgray-100 text-center !mt-4">
      Our World-Class<br class="hidden lg:block" /> HDRI Library<span class="text-orange-500">.</span>
    </h2>
    <div class="z-10 text-center space-y-2">
      <p class="text-lg lg:text-xl text-lightgray-100 font-medium">
        50k HDRI’s suitable for IBL and in-camera content.
      </p>
      <p class="text-lg lg:text-xl text-lightgray-100 font-medium">
        We set out to provide HDRIs every 90 seconds at 50,000px resolution.
      </p>
    </div>
    <div class="hidden md:inline-flex rounded-md shadow z-10">
      <a noPrefetch href="https://mattepaint.com/gallery/hdri/skies/" external class="text-base font-semibold leading-6 text-white hover:underline text-shadow">View our HDRI library <span aria-hidden="true">→</span></a>
    </div>
    <!-- <svg
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="z-10 !mt-12"
    >
      <path d="M12.1699 12.5146L5.5293 5.87402C5.07031 5.41504 5.07031 4.67285 5.5293 4.21875L6.63281 3.11523C7.0918 2.65625 7.83398 2.65625 8.28809 3.11523L12.9951 7.82227L17.7021 3.11523C18.1611 2.65625 18.9033 2.65625 19.3574 3.11523L20.4707 4.21387C20.9297 4.67285 20.9297 5.41504 20.4707 5.86914L13.8301 12.5098C13.3711 12.9736 12.6289 12.9736 12.1699 12.5146ZM13.8301 21.8896L20.4707 15.249C20.9297 14.79 20.9297 14.0479 20.4707 13.5937L19.3672 12.4902C18.9082 12.0312 18.166 12.0312 17.7119 12.4902L13 17.1924L8.29297 12.4854C7.83398 12.0264 7.0918 12.0264 6.6377 12.4854L5.5293 13.5889C5.07031 14.0478 5.07031 14.79 5.5293 15.2441L12.1699 21.8848C12.6289 22.3486 13.3711 22.3486 13.8301 21.8896Z" fill="#F09E47" />
    </svg> -->
  </section>
</template>

<script>
import xlbg from '~/assets/img/hdri/hero/2x.jpg';
import lbg from '~/assets/img/hdri/hero/1x.jpg';
import mbg from '~/assets/img/hdri/hero/mobile.jpg';
export default {
  data() {
    return {
      bg: mbg, // default image
      srcset: `${mbg} 500w, ${lbg} 1024w, ${xlbg} 1920w`,
    };
  }
}
</script>