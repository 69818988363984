<template>
  <div class="flex flex-col w-full max-w-8xl m-auto">
    <div class="flex flex-col-reverse items-center md:flex-row justify-between">
      <div class="px-4 py-6 md:px-12 md:py-8 lg:px-20 lg:py-12 flex flex-col space-y-4 lg:space-y-8 w-full lg:w-1/2">
        <h2 class="text-orange-400 text-xl md:text-2xl lg:text-3xl font-bold flex space-x-2 md:space-x-4 items-center">
          <i class="rounded-full border border-solid border-orange-400 shrink-0 w-10 h-10 md:w-12 md:h-12 flex justify-center items-center">
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.6667 17.5555V18.1111C18.6667 19.0315 17.9205 19.7777 17 19.7777h2.66667C2.74618 19.7777 2 19.0315 2 18.1111V9.22217C2 8.30168 2.74618 7.5555 3.66667 7.5555h3.22222V14.7777C4.22222 16.3094 5.46833 17.5555 7 17.5555H18.6667ZM22 14.7777V5.88883C22 4.96835 21.2538 4.22217 20.3333 4.22217H7C6.07951 4.22217 5.33333 4.96835 5.33333 5.88883V14.7777C5.33333 15.6982 6.07951 16.4444 7 16.4444H20.3333C21.2538 16.4444 22 15.6982 22 14.7777ZM10.8889 7.5555C10.8889 8.47599 10.1427 9.22217 9.22222 9.22217C8.30174 9.22217 7.55556 8.47599 7.55556 7.5555C7.55556 6.63502 8.30174 5.88883 9.22222 5.88883C10.1427 5.88883 10.8889 6.63502 10.8889 7.5555ZM7.55556 12.5555L9.48316 10.6279C9.64587 10.4652 9.90969 10.4652 10.0724 10.6279L11.4444 11.9999L16.1498 7.29456C16.3125 7.13186 16.5764 7.13186 16.7391 7.29456L19.7778 10.3333V14.2222H7.55556V12.5555Z" fill="#E9EAEC" />
            </svg>
          </i>
          <span>
            360° HDRIs
          </span>
        </h2>
        <h3 class="text-3xl lg:text-4xl font-bold text-lightgray-100 leading-10">
          Find the perfect shot, at any time of the day<span class="text-orange-500">.</span>
        </h3>
        <p class="lg:text-lg font-medium">
          The MattePaint HDRI library is shot from a truly unique location with near-zero obstructions.
        </p>
        <p class="lg:text-lg font-medium">
          We set out to capture HDRIs at 1.5 minute intervals at 50,000px resolution and a minimum dynamic range to make them suitable for Image based lighting.
        </p>
      </div>
      <div class="relative w-full lg:w-1/2 flex items-center lg:min-h-[600px]">
        <img :src="bg1" :srcset="srcset1" loading="lazy" alt="Find the perfect shot, at any time of the day." class="w-full object-cover m-0 h-full" />
      </div>
    </div>

    <div class="flex flex-col md:flex-row justify-between">
      <div class="relative w-full lg:w-1/2 flex items-center lg:min-h-[600px]">
        <img :src="bg2" :srcset="srcset2" loading="lazy" alt="Find every detail in every frame." class="w-full object-cover m-0 h-full" />
      </div>
      <div class="px-4 py-6 md:px-12 md:py-8 lg:px-20 lg:py-12 flex flex-col space-y-4 lg:space-y-8 w-full lg:w-1/2 my-auto">
        <h2 class="text-orange-400 text-xl md:text-2xl lg:text-3xl font-bold flex space-x-2 md:space-x-4 items-center">
          <i class="rounded-full border border-solid border-orange-400 shrink-0 w-10 h-10 md:w-12 md:h-12 flex justify-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 text-white"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h1.5C5.496 19.5 6 18.996 6 18.375m-3.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-1.5A1.125 1.125 0 0118 18.375M20.625 4.5h2.375m17.25 0c.621 0 1.125.504 1.125 1.125M20.625 4.5h-1.5C18.504 4.5 18 5.004 18 5.625m3.75 0v1.5c0 .621-.504 1.125-1.125 1.125M3.375 4.5c-.621 0-1.125.504-1.125 1.125M3.375 4.5h1.5C5.496 4.5 6 5.004 6 5.625m-3.75 0v1.5c0 .621.504 1.125 1.125 1.125m0 0h1.5m-1.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m1.5-3.75C5.496 8.25 6 7.746 6 7.125v-1.5M4.875 8.25C5.496 8.25 6 8.754 6 9.375v1.5m0-5.25v5.25m0-5.25C6 5.004 6.504 4.5 7.125 4.5h9.75c.621 0 1.125.504 1.125 1.125m1.125 2.625h1.5m-1.5 0A1.125 1.125 0 0118 7.125v-1.5m1.125 2.625c-.621 0-1.125.504-1.125 1.125v1.5m2.625-2.625c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125M18 5.625v5.25M7.125 12h9.75m-9.75 0A1.125 1.125 0 016 10.875M7.125 12C6.504 12 6 12.504 6 13.125m0-2.25C6 11.496 5.496 12 4.875 12M18 10.875c0 .621-.504 1.125-1.125 1.125M18 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m-12 5.25v-5.25m0 5.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125m-12 0v-1.5c0-.621-.504-1.125-1.125-1.125M18 18.375v-5.25m0 5.25v-1.5c0-.621.504-1.125 1.125-1.125M18 13.125v1.5c0 .621.504 1.125 1.125 1.125M18 13.125c0-.621.504-1.125 1.125-1.125M6 13.125v1.5c0 .621-.504 1.125-1.125 1.125M6 13.125C6 12.504 5.496 12 4.875 12m-1.5 0h1.5m-1.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M19.125 12h1.5m0 0c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h1.5m14.25 0h1.5" />
            </svg>

          </i>
          <span>
            Sequenced at 50,000 pixels!
          </span>
        </h2>
        <h3 class="text-3xl lg:text-4xl font-bold text-lightgray-100 leading-10">
          Find every detail in every frame<span class="text-orange-500">.</span>
        </h3>
        <p class="lg:text-lg font-medium">
          With over 10,000 HDRIs our library is the largest in the world and growing every month.
        </p>
        <p class="lg:text-lg font-medium">
          Our processes continue to lift the bar for the entire industry. We provide you with detailed data of our HDRI sequences to help you get the best result.
        </p>
      </div>
    </div>

    <div class="flex flex-col-reverse items-center md:flex-row justify-between">
      <div class="px-4 py-6 md:px-12 md:py-8 lg:px-20 lg:py-12 flex flex-col space-y-4 lg:space-y-8 w-full lg:w-1/2">
        <h2 class="text-orange-400 text-xl md:text-2xl lg:text-3xl font-bold flex space-x-2 md:space-x-4 items-center">
          <i class="rounded-full border border-solid border-orange-400 shrink-0 w-10 h-10 md:w-12 md:h-12 flex justify-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6 text-white"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
            </svg>

          </i>
          <span>
            Reduced noise and compression
          </span>
        </h2>
        <h3 class="text-3xl lg:text-4xl font-bold text-lightgray-100 leading-10">
          Perfect for 4k film productions<span class="text-orange-500">.</span>
        </h3>
        <p class="lg:text-lg font-medium">
          MattePaint HDRIs set the standard for quality, with the highest signal-to-noise ratio which exceeds the needs of even the most demanding 4K productions.
        </p>
        <p class="lg:text-lg font-medium">
          Our advanced capture processes ensures each HDRI is meticulously crafted to minimize noise, offering unparalleled clarity and fidelity. Choose MattePaint HDRIs for visuals that meet the highest demands of clarity and detail in your 4K projects.
        </p>
        <div class="inline-flex rounded-md shadow">
          <a noPrefetch href="https://assets.mattepaint.com/mattePaint_hdri_seq054_50000px_x1154.zip" external class="inline-flex justify-center rounded-md border border-transparent bg-orange-500 px-5 py-3 text-base font-medium text-white hover:bg-orange-400 items-center">
            <span>Download sample pack</span> <small class="ml-1.5">(200mb)</small>
          </a>
        </div>
      </div>
      <div class="relative w-full lg:w-1/2 flex items-center lg:min-h-[600px]">
        <ClientOnly>
          <LazyHdriFeatureNoise v-if="hasInit" />
        </ClientOnly>
      </div>
    </div>
  </div>
</template>

<script>
import xlbg1 from '~/assets/img/hdri/features/1/2x.png';
import lbg1 from '~/assets/img/hdri/features/1/1x.png';
import mbg1 from '~/assets/img/hdri/features/1/mobile.png';

import xlbg2 from '~/assets/img/hdri/features/2/2x.jpg';
import lbg2 from '~/assets/img/hdri/features/2/1x.jpg';
import mbg2 from '~/assets/img/hdri/features/2/mobile.jpg';

export default {
  data() {
    return {
      bg1: mbg1, // default image
      srcset1: `${mbg1} 500w, ${lbg1} 1024w, ${xlbg1} 1920w`,
      bg2: mbg2, // default image
      srcset2: `${mbg2} 500w, ${lbg2} 1024w, ${xlbg2} 1920w`,
    };
  }
}
</script>