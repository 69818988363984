<template>
  <div class="relative px-4 pt-16 pb-20 sm:px-6 lg:px-8 lg:pt-24 lg:pb-28">
    <div class="z-0">
      <img class="absolute bottom-0 inset-0 object-cover top-0 left-0 right-0 h-full w-full max-w-none z-0" :src="bg" :srcset="srcset" alt="" />
      <div class="z-10 absolute bottom-0 inset-0 bg-gradient-to-t from-black-900/30 to-transparent" />
    </div>
    <div class="relative mx-auto max-w-7xl z-10">
      <div class="text-center lg:space-y-10">
        <h2 class="text-3xl font-bold tracking-tight text-lightgray-100 sm:text-4xl">
          Find every detail in every frame<span class="text-orange-500">.</span>
        </h2>
        <p class="mx-auto mt-3 max-w-4xl text-xl text-lightgray-200 sm:mt-4">
          We provide you with detailed data of our HDRI sequences to help you get the best result. Every HDRI has embedded metadata for Sun intensity, Elevation, Azimuth, Frame and more, allowing your team to integrate our entire library with your pipeline.
        </p>
      </div>
      <div class="mx-auto mt-12 grid max-w-lg gap-6 lg:max-w-none lg:grid-cols-3">
        <div v-for="post in posts" :key="post.title" class="flex flex-col overflow-hidden rounded-lg shadow-lg">
          <div class="flex-shrink-0">
            <img class="h-48 w-full object-cover" :src="post.imageUrl" :alt="post.title" loading="lazy" />
          </div>
          <div class="flex flex-1 flex-col justify-between bg-black-800/80 p-6">
            <div class="flex-1">
              <div class="mt-2 block">
                <p class="text-xl font-semibold text-orange-400">
                  {{ post.title }}
                </p>
                <div class="mt-4 text-base text-lightgray-300 space-y-2">
                  <div v-for="(item, k) in post.items" :key="k" class="flex space-x-4 items-center">
                    <i class="rounded-lg bg-black-800/80 p-2"><component :is="item.icon" class="h-6 w-6 text-lightgray-500" aria-hidden="true" /></i>
                    <span class="text-base">{{ item.label }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import xlbg from '~/assets/img/hdri/details/2x.jpg';
import lbg from '~/assets/img/hdri/details/1x.jpg';
import mbg from '~/assets/img/hdri/details/mobile.jpg';

import p1 from '~/assets/img/home/hero/mobile.jpg';
import p2 from '~/assets/img/hdri/features/2/mobile.jpg';
import p3 from '~/assets/img/hdri/quote/mobile.jpg';

import { SunIcon, Square3Stack3DIcon, ClockIcon, LightBulbIcon, MapPinIcon, PhotoIcon, ChevronDoubleUpIcon, RectangleStackIcon, FolderIcon, ArrowsPointingOutIcon, BoltIcon } from '@heroicons/vue/20/solid'

const posts = [
  {
    title: 'Frame details',
    href: '#',
    items: [
      {
        icon: SunIcon,
        label: 'Sun elevation',
      },
      {
        icon: Square3Stack3DIcon,
        label: 'Number of frames',
      },
      {
        icon: ClockIcon,
        label: 'Time',
      },
      {
        icon: LightBulbIcon,
        label: 'Luminance',
      },
    ],
    imageUrl: p1
  },
  {
    title: 'Sequence information',
    href: '#',
    items: [
      {
        icon: SunIcon,
        label: 'Sun elevation range',
      },
      {
        icon: MapPinIcon,
        label: 'Sun heading range',
      },
      {
        icon: ClockIcon,
        label: 'Time of day',
      },
      {
        icon: LightBulbIcon,
        label: 'Luminance range',
      },
      {
        icon: PhotoIcon,
        label: 'Total frames',
      },
    ],
    imageUrl:
      p2
  },
  {
    title: 'Extra details',
    href: '#',
    items: [
      {
        icon: ChevronDoubleUpIcon,
        label: 'Sunlight',
      },
      {
        icon: RectangleStackIcon,
        label: 'Dynamic Range',
      },
      {
        icon: ArrowsPointingOutIcon,
        label: 'Max resolution',
      },
      {
        icon: FolderIcon,
        label: 'File format',
      },
      {
        icon: BoltIcon,
        label: 'White balance',
      },
    ],
    imageUrl:
      p3
  },
]

export default {
  data() {
    return {
      bg: mbg, // default image
      srcset: `${mbg} 500w, ${lbg} 1024w, ${xlbg} 1920w`,
      posts,
    };
  }
}
</script>